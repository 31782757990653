<template>
 <div :class="windowClass">
   <v-container>
     <template >
      <v-row  no-gutters>
        <v-col cols="4" class="pa-1">
           <va-stat-card 
              color="tertiary"
              icon="mdi-cash-register"
              label="TODAY'S GROSS"
              :data="$money(TOTALGROSS)"
              sup="PHP"
            />
        </v-col>

         <v-col cols="4" class="pa-1">
           <va-stat-card 
              color="success"
              icon="mdi-account-cash-outline"
               label="TODAY'S PAYOUT"
              :data="$money(TOTALPAYOUT)"
              sup="PHP"
            />
        </v-col>

          <v-col cols="4" class="pa-1">
           <va-stat-card 
              color="secondary"
              icon="mdi-ticket-confirmation"
            label="TODAY'S HITS"
              :data="$money(gross.TOTALHITS)"
            />
        </v-col>
    </v-row>
     </template>
      <v-row class="pa-2">
          <v-col cols="12">
             <v-card dense>
        <v-toolbar>
            TRANSACTIONS
           <v-spacer/>
           As of : {{ this.$moment().format('MMM DD, YYYY')}}
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            
            <table class="va-table def-font" id="over-table">
                <tr>
                    <!-- <th>DRAW ID</th> -->
                    <th>GAME</th>
                    <!-- <th>STATUS</th> -->
                    <th>DRAW</th>
                    <th class="text-right">GROSS</th>
                    <th class="text-right">UNIT HITS</th>
                    <th class="text-right">AMOUNT HITS</th>
                </tr>
                 <tr v-for="(item,index) in items" :key="index" >
                     <!-- <td class="border_bot">{{item.draw_id}} </td> -->
                    <td class="border_bot">{{item.game}} </td>
                    <!-- <td class="border_bot">{{item.status}} </td> -->
                    <td class="border_bot">{{item.draw_date}}</td>
                    <td class="text-right border_bot">{{$money(item.total)}}</td>
                    <td class="text-right border_bot">{{$money(item.hits)}}</td>
                    <td class="text-right border_bot">{{$money(item.win_amount)}}</td>
                </tr>
                <tr>
                    
                     <th  class="text-right" style="background: #efefef;" colspan="2">TOTAL:</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALHITS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th>
                </tr>
               
            </table>
        </v-card-text>     
    </v-card>
        </v-col>


         <v-col cols="12">
             <v-card dense>
        <v-toolbar>
            Today's Operation 
           <v-spacer/>
           As of : {{ this.$moment().format('MMM DD, YYYY')}}
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            
            <table class="va-table def-font" id="over-table">
                <tr>
                    <!-- <th>DRAW ID</th> -->
                    <th>GAME</th>
                    <th class="text-right">GROSS</th>
                    <th class="text-right">AMOUNT HITS</th>
                    <th class="text-right">REVENUE</th>
                </tr>
                 <tr v-for="(item,index) in PERGAMES" :key="index" >
                     <!-- <td class="border_bot">{{item.draw_id}} </td> -->
                    <td class="border_bot">{{item.game}} </td>
                   <td class="text-right border_bot">{{$money(item_gross(item.data))}}</td>
                    <td class="text-right border_bot">{{$money(item_payout(item.data))}}</td>
                     <td class="text-right border_bot" :class="item_revenvue(item_gross(item.data), item_payout(item.data))>-1?'text-success':'text-error'">{{$money(item_revenvue(item_gross(item.data),item_payout(item.data)))}}</td>
                </tr>
                <tr>
                    
                     <th  class="text-right" style="background: #efefef;">TOTAL:</th>
                      <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th>
                    <th class="text-right"  style="background: #efefef;" :class="item_revenvue(TOTALGROSS,TOTALPAYOUT)>-1?'text-success':'text-error'">{{$money(item_revenvue(TOTALGROSS,TOTALPAYOUT))}}</th>
                </tr>
               
            </table>
        </v-card-text>     
    </v-card>
        </v-col>
      
        
      </v-row>
   </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
    mixins: [API_MIXINS],
    data: () => ({
        overlay: false,
          gross:'',
          loading: false,
          data_open_draws:[],
          items:[],
          inter_1: null,
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getTransactions()
          this.inter_1 = setInterval(()=>{  this.getTransactions()}, 100000)
        } 
    },
    beforeDestroy(){
       if(this.inter_1 != null) { clearInterval(this.inter_1)}
       return true
    },
    computed:{ 
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
        PERGAMES() {
            var dataArr = []
            var bygames = this.$groupBy('game')
            for (const [key, value] of Object.entries( bygames(this.items))) {
                    var item = { game: key, data:value}
                   dataArr.push(item)
            }
            return dataArr
        },
      TOTALGROSS() {
          var total = this.items.reduce((res,item)=>res += this.$IsNum(item.total),0)
          return total
      },
        TOTALHITS() {
          var total = this.items.reduce((res,item)=>res += this.$IsNum(item.hits),0)
          return total
      },
        TOTALPAYOUT() {
          var total = this.items.reduce((res,item)=>res += this.$IsNum(item.win_amount),0)
          return total
      },
      TOTALCOMM() {
        var comm = 0
        for (var i=0;i<this.PERGAMES.length;i++) {
          var item = {game: this.PERGAMES[i].game, data: this.PERGAMES[i].data}
        
            comm += this.item_comm(item.data, item.game)
        }
        return comm
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOpenDraws']),
      item_revenvue(gross, hits){
        return gross - hits
      },
      item_gross(items) {
          return items.reduce((res,item)=>res += this.$IsNum(item.total),0)
      },
       item_comm(items, game) {
         if(game == 'PICK3') {
           return items.reduce((res,item)=>res += this.$IsNum(item.gross),0) * .3
         } else if(game =='3D') {
            return items.reduce((res,item)=>res += this.$IsNum(item.gross),0) * .3
         } else if(game =='4D') {
            return items.reduce((res,item)=>res += this.$IsNum(item.gross),0) * .3
         } else if(game =='LASTTWO') {
            return items.reduce((res,item)=>res += this.$IsNum(item.gross),0) * .22
         } else {
           return 0
         }
          
      },
       item_payout(items) {
          return items.reduce((res,item)=>res += this.$IsNum(item.win_amount),0)
      },
      getTransactions(){
       //alert("call")
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.$moment().format('YYYY-MM-DD')
            param.to_date =  param.from_date
            param.Method = "perdraw_partner"
             this.$http.post("report/transactions",param).then(response => {
                response.data.transactions !=null?this.items = response.data.transactions:this.items =[]
                console.log(33)
                this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },   
    }
  }
</script>